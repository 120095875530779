.ant-tabs-card > .ant-tabs-content-holder {
    padding: 10px !important;    
    border: 1px solid #ebebeb !important;    
    border-top: none !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav
{
    margin: 0px !important;
}

/*
.dx-switch-on-value .dx-switch-handle::before {
    background-color: #159f35!important;
  }
*/

@success-btn-color: #159f35;
@warning-btn-color: #eca52b;
@info-btn-color: #118dd9;
@danger-btn-color: #ce5e5e;
@dark-btn-color: #343a40;
@light-dark-btn-color: #6c757d;
@buttons: 
dark @dark-btn-color, 
lightdark @light-dark-btn-color, 
success @success-btn-color, 
warning @warning-btn-color, 
danger @danger-btn-color, 
info @info-btn-color;
.getButtons(@index:1) when(@index <= length(@buttons)) {
    @name: extract(extract(@buttons, @index),1);
    @color: extract(extract(@buttons, @index),2);
    @colorHoverFocus: fade(@color, 75%);
    @colorHoverBorderFocus: fade(@color, 10%);
    @colorActive: darken(@color, 10%);
    .ant-btn-default.@{name}{
        color: #fff;
        background-color: @color;
        border-color: @color;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-default.@{name}-disabled,
    .ant-btn-default.@{name}.disabled,
    .ant-btn-default.@{name}[disabled],
    .ant-btn-default.@{name}-disabled:hover,
    .ant-btn-default.@{name}.disabled:hover,
    .ant-btn-default.@{name}[disabled]:hover,
    .ant-btn-default.@{name}-disabled:focus,
    .ant-btn-default.@{name}.disabled:focus,
    .ant-btn-default.@{name}[disabled]:focus,
    .ant-btn-default.@{name}-disabled:active,
    .ant-btn-default.@{name}.disabled:active,
    .ant-btn-default.@{name}[disabled]:active,
    .ant-btn-default.@{name}-disabled.active,
    .ant-btn-default.@{name}.disabled.active,
    .ant-btn-default.@{name}[disabled].active {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .ant-btn-default.@{name}:hover,
    .ant-btn-default.@{name}:focus {
        color: #fff;
        background-color: @colorHoverFocus;
        border-color: @colorHoverBorderFocus;
    }
    .ant-btn-default.@{name}:active,
    .ant-btn-default.@{name}.active {
        color: #fff;
        background-color: @colorActive;
        border-color: @colorActive;
    }
    .ant-btn-background-ghost.ant-btn-default.@{name} {
        color: @color;
        background: transparent;
        border-color: @color;
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-default.@{name}:hover, 
    .ant-btn-background-ghost.ant-btn-default.@{name}:focus {
        color: @colorHoverFocus;
        background: transparent;
        border-color: @colorHoverFocus;
    }
    .ant-btn-background-ghost.ant-btn-default.@{name}:active, 
    .ant-btn-background-ghost.ant-btn-default.@{name}.active {
        color: @color;
        background: transparent;
        border-color: @colorActive;
    }
    .getButtons(@index + 1);
};

.getButtons();

.disable-animation:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
