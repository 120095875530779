.dotted-label {
  display: block;
  width: 100%;
  border-bottom: 1px dotted #cccccc;
}

.ant-form-vertical.direction-row .ant-row {
  flex-direction: row;
}

.accent-header {
  color: #337ab7;
  font-weight: 600;
}

.accent-cell {
  color: #337ab7;
}

.ant-progress-circle {
  margin: 0 !important;
}

@color-bg: '#22262E';
@color-heading: '#798EB0';
@color-counter: '#8973FD';
@font-family-heading: 'Tahoma';

.countdown-app {
  color: black;
  display: flex;
  width: 300px;
}

.countdown-container {
  margin: auto;
  padding: 10px;
}

.countdown-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
}

.countdown-date {
  margin-bottom: 5px;

  & h1 {
    color: @color-heading;
    font-family: @font-family-heading;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.1875em;
    margin: unset;
    text-align: center;
    text-transform: uppercase;
  }
}

.countdown-counter {
  background: rgba(255, 255, 255, 0.025);
  border-radius: 5px;
  color: @color-counter;
  display: flex;
  flex-direction: column;
  font-family: 'Tahoma';
  font-size: 12pt;
  font-weight: 100;
  line-height: 1;
  text-align: center;
  & h2 {
    color: @color-heading;
    font-family: @font-family-heading;
    font-size: 8pt;
    font-weight: 300;
    letter-spacing: 0.1875em;
    margin: 1.25rem 0 0;
    order: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
  }
}
