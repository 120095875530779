.slick-slider.slt-slick {
  margin-bottom: 0;
}

.claim-profile-wrapper {
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.9rem;
}

.claim-profile-p-label {
  display: inline-block;
  margin: 0 0.5rem 0 0;
  line-height: 0.5rem;
  color: rgba(0, 0, 0, 0.85);
}
.gx-user-popover {
  padding: 0;

  & li {
    padding: 0;

    &:first-child a.gx-login-form-forgot {
      padding-top: 7px;
    }

    &:last-child a.gx-login-form-forgot {
      padding-bottom: 7px;
    }
  }

  & a.gx-login-form-forgot {
    width: 100%;
    display: inline-block;
    padding: 3px 15px;
  }
}
.slt-progress {
  & .ant-progress-text {
    width: initial;
  }
}
.slt-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.highlighted,
.slt-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td.highlighted {
  box-shadow: inset 0 0 0 2em rgba(255, 155, 0, 0.125);
}
@keyframes mail-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slt-datagrid .dx-data-row td {
  &.sending-in-progress,
  &.mail-sent {
    position: relative;
  }

  &.sending-in-progress:after {
    display: block;
    content: '';
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #8dc0eb;
    border-radius: 0.5rem;
    animation: mail-blink 2s infinite;
    opacity: 1;
  }

  &.mail-sent:after {
    display: block;
    content: '';
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #54ac8f;
    border-radius: 0.5rem;
  }
}

.information-notice-start-btn {
  display: none;
}

.slt-datagrid {
  & .dx-data-row {
    &.dx-state-hover {
      & .information-notice-start-btn {
        display: inline-block;
      }
    }

    & .slt-narrow-cell {
      padding: 0.25rem 0.5rem;
    }

    & .slt-relative {
      position: relative;
    }

    & .slt-absolute {
      position: absolute;
    }
  }
}

.slt-layout {
  & [class^='ant-layout-'] {
    box-shadow: none;
  }

  & .ant-form {
    & .ant-form-item:last-child {
      margin-bottom: 0;
    }
    padding: 0.75rem 1rem;
  }

  & .slt-skeleton {
    width: 100%;
    height: 36px;
    background: #777;
    opacity: 0.75;
    animation: slt-skeleton-blink-anim infinite 1s ease-in-out;
    border-radius: 0.25rem;

    &.blue {
      background: #77a5cd;
    }
  }

  & .slt-skeleton-blink {
    background: #777;
    opacity: 0.75;
    animation: slt-skeleton-blink-anim infinite 1s ease-in-out;

    &.blue {
      background: #77a5cd;
    }
  }

  & .bg-abs {
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(100% - 32px);
  }

  & .slt-badge {
    margin-bottom: 0;
  }

  & .slt-custom-tooltip-set .ant-form-item-label > label::after {
    margin-left: 1.25rem;
  }

  & .ant-spin-nested-loading > div > .ant-spin {
    max-height: initial;
  }

  & .slt-force-dir-col .ant-form-item-row {
    flex-direction: column;
    & [class*='ant-col-'] {
      width: 100%;
      max-width: initial;
      margin-bottom: 0px;
    }
  }
}

@keyframes slt-skeleton-blink-anim {
  0% {
    opacity: 0.75;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.75;
  }
}
